.top {
	
	.logo {
		position: relative;
		z-index: 210;
	}
}


nav{
	
	.menu {

		.socials-mob {
			display: none;
			
			@media #{$phone}{
				display: block;
			    position: absolute;
			    width: 100px;
			    height: 40px;
			    top: 60px;
			    left: 43%;
			}

			a {
				&:last-child {
					margin-left: 20px;
					
					img {
						width: 37px;
					}
				}
			}

		}

		.contacts-mob {
			display: none;
			
			@media #{$phone}{
				display: block;
				position: absolute;
				bottom: 0;
				width: 100%;
				padding: 30px;
				background: #2A3848;
				
				.field {
					margin-bottom: 30px;
					
					span {
						font-size: 14px;
						opacity: 0.5;
					}

					a {
						color: #fff;
						text-decoration: none;
					}
				}
			}
		}


		ul {
			display: flex;
			margin-top: 40px;
			-webkit-flex-direction: row;
			    -ms-flex-direction: row;
			        flex-direction: row;
			-webkit-justify-content: space-between;
			        justify-content: space-between;
			
			li {
				position: relative;
				display: block;
				
				&.active {
					a {
						font-weight: bold;
					}
				}
				
				&:after {
					content: "";
					display: block;
					position: absolute;
					width: 13px;
					height: 13px;
					top: 25px;
					left: 50%;
					opacity: 0;
					transform: translateX(-50%);
					background: url(../img/icons/menu-b.svg) center no-repeat;
					-webkit-background-size: contain;
					     -o-background-size: contain;
					        background-size: contain;
				}
				

				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 30px;
					width: 0%;
					height: 2px;
					background: $purple;
				}

				&:hover {
					&:before {
						width: 100%;
						transition: width 0.8s $ease;
					}

					&:after{
						opacity: 1;
						transition: opacity 0.4s $ease 0.8s;
					}
				}

				a {
					text-decoration: none;
					color: $white;
					position: relative;
					display: block;
					
					
					
					&:hover {
						font-weight: 600;
					}
				}
			}
		}

		@media #{$phone}{
			position: absolute;
			width: 95%;
			height: 100vh;
			background: #202B38;
			right: -100%;
			top: -30px;
			z-index: 200;
			transition: right 0.6s $ease;
			
			&-shown {
				right: 0;
				transition: right 0.6s $ease;
			}
			
			ul {
				height: 85vh;
				display: flex;
				-webkit-align-items: center;
				        align-items: center;
				-webkit-justify-content: center;
				        justify-content: center;
				-webkit-flex-direction: column;
				    -ms-flex-direction: column;
				        flex-direction: column;
				    
				li {
					display: block;
					margin-bottom: 20px;
					padding-bottom: 40px;
					width: 70%;
					text-align: center;
					margin-right: 0;
					
					&:after {
						content: "";
						display: block;
						position: absolute;
						width: 13px;
						height: 13px;
						top: 50px;
						left: 50%;
						opacity: 11;
						transform: translateX(-50%);
						background: url(../img/icons/menu-b.svg) center no-repeat;
						-webkit-background-size: contain;
						     -o-background-size: contain;
						        background-size: contain;
					}
					

					&:before {
						content: "";
						display: block;
						position: absolute;
						top: 55px;
						width: 100%;
						height: 2px;
						background: $purple;
					}
					
					a {
						font-weight: 400;
						font-size: 24px;
					}
				}

			}
		}
	}


	.nav-icon {
		width: 47px;
		right: 20px;
		height: 40px;
		top: 30px;
		position: absolute;
		z-index: 205;
		cursor: pointer;
		display: none;
		
		@media #{$phone}{
			display: block;
		}
		
		span {
			display: block;
			width: 47px;
			height: 3px;
			background: $white;
			//margin-bottom: 12px;
			position: relative;
			transition: all 0.6s $ease;
			
			&:nth-child(1) {
				top: 0;
				margin-left: auto;
			}
			
			&:nth-child(2) {
				top: 10px;
				width: 38px;
				margin-left: auto;
			}

			&:nth-child(3) {
				top: 20px;
				width: 38px;
				margin-left: auto;
			}
		}


		&-close {
			span {
				&:nth-child(1) {
					top: 15px;
					opacity: 0;
					width: 38px;
					transition: top 0.6s $ease, width 0.2s $ease 0.6s, opacity 0.3s $ease 0.7s;
				}
				
				&:nth-child(2) {
					top: 12px;
					transform: rotate(45deg);
					transition: transform 0.6s $ease 0.8s;
				}

				&:nth-child(3) {
					top: 9px;
					transform: rotate(-45deg);
					transition: top 0.6s $ease, transform 0.6s $ease 0.8s;
				}

			}
		}
	}
}

.socials {
	height: 102px;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	
	@media #{$phone}{
		display: none;
	}
	
	a {
		img {
			width: 25px;
		}

		&:last-child {
			
			img {
				width: 32px;
			}
		}
	}
}





