@import url('https://fonts.googleapis.com/css2?family=Istok+Web:wght@400;700&display=swap');

html{
	-ms-overflow-x: hidden;
	    overflow-x: hidden;
}

body {
	background: #192029;
	color: #fff;
	overflow-x: hidden;
	font-family: 'Istok Web', sans-serif;
	font-weight: 400;
	font-size: 16px;
	text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
	-ms-overflow-x: hidden;
	    overflow-x: hidden;
	
	@media #{$phone}{
		width: 100%;
		font-size: 16px;
		-ms-overflow-x: hidden;
		    overflow-x: hidden;
		//background: #FFF;
	}
}

body::-webkit-scrollbar {
  width: 0.5em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: #6123E7;
  outline: 1px solid slategrey;
}

p {
	color: #FFF;
	font-size: 16px;
	line-height: 1.3;
}

img {
	@media #{$phone}{
		max-width: 100%;
		//width: 100%;
	}
}

.header {
	margin-bottom: 40px;
}



.devider {
	display: block;
	margin: 30px 0;
	width: 350px;
	height: 26px;
	background: url(../img/title-emblem.svg) left center no-repeat;
	-webkit-background-size: contain;
	     -o-background-size: contain;
	        background-size: contain;
}


.title {
	position: absolute;
	left: -1px;
	top: 0;
	transform: rotate(90deg);
	transform-origin: top left;
	display: flex;
	-webkit-flex-direction: row;
	    -ms-flex-direction: row;
	        flex-direction: row;
}

.title .title-text {
	padding-top: 10px;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 20px;
}

.hero {
	height: 800px;
	background: url(../img/hero-bg.jpg) center no-repeat;
	-webkit-background-size: cover;
	     -o-background-size: cover;
	        background-size: cover;
}





.common {

	&.games {
		background: url(../img/games-bg.jpg) top center no-repeat;
		background-size: 100%;
		
		@media #{$phone}{
			background-size: 200%;	
		}
	}

	&.news {
		background: url(../img/news-bg.jpg) top center no-repeat;
		background-size: 100%;
		
		@media #{$phone}{
			background-size: 200%;	
		}
	}

	&.news-detail {
		background: url(../img/news-detail-bg.jpg) top center no-repeat;
		background-size: 100%;
		
		@media #{$phone}{
			background-size: 200%;	
		}
		
		.back {
			
		}

		.middle {
			padding: 80px 0 100px;
			
			@media #{$phone}{
				
			}
		}
		
		h1 {
			font-size: 64px;
			text-align: left;
			padding: 20px 0 20px;
			
			@media #{$phone}{
				font-size: 42px;
			}
		}

		.date {
			opacity: 0.6;
			font-family: 'Istok web';
		}

		.card-news {
			background: #334457;
			position: relative;
			padding: 80px 100px;
			margin-bottom: 25px;
			-webkit-border-radius: 0;
			        border-radius: 0;
			
			@media #{$phone}{
				padding: 20px;
			}
			
			&:after {
				content: "";
				width: 56px;
				height: 56px;
				position: absolute;
				right: -1px;
				top: -1px;
				background: url(../img/icons/news-d-top.svg) center no-repeat;
				-webkit-background-size: contain;
				     -o-background-size: contain;
				        background-size: contain;
			}
			
			h2 {
				font-size: 32px;
				margin-bottom: 20px;
				font-weight: bold;
			}

			h3 {
				font-size: 28px;
				margin-bottom: 20px;
				font-weight: bold;
			}

			img {
				margin-bottom: 40px;
				
				@media #{$phone}{
					max-width: 100%;
				}
			}
			
			p{
				font-size: 18px;
				line-height: 28px;
				opacity: 0.7;
				margin-bottom: 40px;
				font-family: 'Istok Web',sans-serif;
			}
		}
	}

	&.contacts {
		background: url(../img/contacts-bg.jpg) top center no-repeat;
		background-size: 100%;
		
		@media #{$phone}{
			background-size: 200%;	
		}
		
		h1{
			@media #{$phone}{
				font-size: 64px;
			}
		}
		
		.card-news-all {
			min-height: 320px;
			padding-top: 30px;
			padding-bottom: 30px;
			
			.field {
				margin-bottom: 30px;
				
				span {
					font-size: 14px;
					opacity: 0.5;
				}

				.icons {
					margin-top: 15px;
					a {
						
						&:last-child {
							margin-left: 10px;
							img {
								width: 38px;
							}
						}
					}
				}
			}
		}
	}

	&.aboutus {
		background: url(../img/aboutus-bg.jpg) top center no-repeat;
		background-size: 100%;
		
		@media #{$phone}{
			background-size: 200%;	
		}
		
		h1 {
			padding-bottom: 60px;
			
			@media #{$phone}{
				font-size: 64px;
			}
		}

		.middle {
			text-align: center;
			

			h4 {
				font-size: 40px;
				font-weight: bold;
				letter-spacing: 5%;
				margin-top: 60px;
				margin-bottom: 80px;
			}
		}

		.content {
			
			.container {
				padding: 0 240px;
				
				@media #{$phone}{
					padding: 20px;
				}
				
				img {
					display: block;
					margin: 0 auto 40px;
					
					@media #{$phone}{
						max-width: 100%;
					}
					
					&.img-2 {
						margin-bottom: 30px;
					}
				}

				p {
					margin-bottom: 40px;
					font-size: 18px;
					line-height: 28px;
				}
			}
		}
	}

	&.career-sec {
		background: url(../img/career-bg.jpg) top center no-repeat;
		background-size: 100%;
		
		@media #{$phone}{
			background-size: 200%;	
		}
		
		h1 {
			padding-bottom: 60px;
			
			@media #{$phone}{
				font-size: 64px;
			}
		}


		.middle {
			text-align: center;
			margin-bottom: 80px;
		}
		
		.content {
			
			.container {
				padding: 0 240px;
				
				@media #{$phone}{
					padding: 20px;
				}
				
				img {
					display: block;
					margin: 0 auto 40px;
				}

				p {
					margin-bottom: 40px;
					font-size: 24px;
					line-height: 32px;
					
					span {
						font-weight: bold;
						display: inline-block;
						position: relative;
						z-index: 0;
						
						&:after {
							content: "";
							display: block;
							width: 100%;
							height: 5px;
							position: absolute;
							bottom: 7px;
							background: $purple;
							z-index: -1;
						}
					}
				}

				.number {
					font-size: 100px;
					font-weight: bold;
					position: absolute;
					color: #192029;
					bottom: -30px;
					left: 20px;
				}
			}

			.card-career{
				padding: 15px 25px 30px;
				margin-bottom: 40px;
				position: relative;
				background: url(../img/c-1-bg.jpg) center no-repeat;
				-webkit-background-size: cover;
				     -o-background-size: cover;
				        background-size: cover;
				
				&:after {
					content: "";
					display: block;
					position: absolute;
					right: 0;
					bottom: 0;
					width: 50px;
					height: 50px;
					background: url(../img/card-corner.svg) center no-repeat;
					-webkit-background-size: cover;
					     -o-background-size: cover;
					        background-size: cover;
				}
				
				p {
					font-size: 22px;
					font-weight: bold;
					
					

				}
			}
		}
	}
	
	h1 {
		font-size: 100px;
		font-weight: 600;
		text-align: center;
		padding: 80px 0 120px;
	}

	.content {
		padding-bottom: 100px;
	}
}

.marketplace {
	height: 400px;
	position: relative;
	z-index: 10;
	margin-bottom: 80px;
	background: url(../img/marketplace-bg.jpg) center no-repeat;
	-webkit-background-size: cover;
	     -o-background-size: cover;
	        background-size: cover;
	
	@media #{$phone}{
		height: auto;
		padding-bottom: 80px;
	}
	img {
		transform: translateY(-15%);
	}
	    
	p {
		font-size: 40px;
		font-weight: bold;
		padding-left: 0px;
		margin-bottom: 40px;
		margin-top: 80px;
		
		@media #{$phone}{
			font-size: 24px;
			margin-top: 20px;
		}
	}
}

.joinus {
	position: relative;
	z-index: 1;
	height: 500px;
	margin-bottom: 80px;
	background: url(../img/career-off-bg.jpg) center no-repeat;
	-webkit-background-size: cover;
	     -o-background-size: cover;
	        background-size: cover;
	
	@media #{$phone}{
		height: auto;
		padding: 40px 0;
	}
	
	h4 {
		font-size: 40px;
		font-weight: bold;
		padding-top: 100px;
		margin-bottom: 30px;
	}

	p {
		font-size: 32px;
		margin-bottom: 30px;
	}

	img {
		@media #{$phone}{
			transform: translateY(-10%);
		}
	}
}

.writeus {
	padding-top: 200px;
	padding-bottom: 0;
	z-index: 20;
	position: relative;
	
	@media #{$phone}{
		padding: 60px 0;
	}
	
	h4 {
		padding: 0 100px 0;
		font-size: 40px;
		font-weight: bold;
		
		@media #{$phone}{
			padding: 0;
		}
		
		span {
			font-weight: bold;
			display: inline-block;
			position: relative;
			z-index: 0;
			margin-top: 40px;
			
			&:after {
				content: "";
				display: block;
				width: 100%;
				height: 5px;
				position: absolute;
				bottom: 7px;
				background: $purple;
				z-index: -1;
			}
		}
	}

	p {
		padding: 0 100px 0;
		font-size: 24px;
		margin-top: 40px;
		
		@media #{$phone}{
			padding: 0;
			font-size: 24px;
		}
	}

	img {
		transform: translateY(-30%);
		
		@media #{$phone}{
			transform: translateY(0);
		}
	}
}

.card-news-all {
	min-height: auto;
	padding: 0 30px 60px 30px;
	position: relative;
	z-index: 0;
	background: #334457;
	margin-bottom: 25px;
	-webkit-border-radius: 0;
	        border-radius: 0;
	
	a {
		color: #FFF;
		text-decoration: none;
	}
	
	&:hover {
		.overlay {
			opacity: 1;
			transition: opacity 0.8s $ease;
		}

		.date{
			//background: darken($blue, 70);
		}
	}
	
	.overlay {
		position: absolute;
		z-index: -1;
		opacity: 0;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		transition: opacity 0.8s $ease;
		background: url(../img/news-card-bg.png) center no-repeat;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	
	.date {
		padding: 10px 15px;
		display: inline-block;
		width: max-content;
		text-align: center;
		background: #485F78;
		color: transparentize($white, 0.5);
		margin-bottom: 20px;
		
		span {
			font-size: 14px;
		}
	}
	
	h3 {
		font-size: 32px;
		padding: 0;
		margin-bottom: 20px;
	}

	.desc {
		padding: 0;
		opacity: 0.6;
		margin-bottom: 20px;
	}
}

.other-news {
	position: relative;
	z-index: 10;
	h2 {
		text-align: center;
		position: relative;
		font-size: 36px;
		font-weight: bold;
		margin-bottom: 40px;
		
		&:before {
			content: "";
			width: 300px;
			height: 28px;
			position: absolute;
			left: 0;
			background: url(../img/title-el-l.svg) center no-repeat;
			-webkit-background-size: contain;
			     -o-background-size: contain;
			        background-size: contain;
		}

		&:after {
			content: "";
			width: 300px;
			height: 28px;
			position: absolute;
			right: 0;
			background: url(../img/title-el-r.svg) center no-repeat;
			-webkit-background-size: contain;
			     -o-background-size: contain;
			        background-size: contain;
		}
	}
}

.middle {
 	height: 100%;
 	display: flex;
 	-webkit-align-items: center;
 	        align-items: center;
}

.middle .main-logo {
	width: 50%;
	margin-bottom: 80px;
	padding-left: 50px;
}

.middle {
	.button {
		width: 55%;
		text-align: center;
		margin-bottom: 80px;
		
		@media #{$phone}{
			width: 100%;
		}
	}
}

.middle .scroll {
	width: 50%;
	padding-left: 50px;
	display: flex;
	-webkit-flex-direction: column;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
}


.middle .scroll img {
	width: 60%;
	display: block;
	margin-bottom: 20px;
}

.scroll-animated {
	animation: scroll 2s ease-in-out infinite;
}

@keyframes scroll {
	50% {
		transform: translateY(10px);
	}
} 

.chars {
	width: 19%;
    position: absolute;
    right: 410px;
    top: 90px;
}

.chars img {
	width: 600px;
}

.topgames {
	padding: 120px 0;
}


.card {
	background: #334457;
	position: relative;
	padding: 15px;
	margin-bottom: 25px;
	min-height: 480px;
	-webkit-border-radius: 0;
	        border-radius: 0;
}

.card .card-img {
	-webkit-border-radius: 0;
	        border-radius: 0;
	height: 230px;
	margin-bottom: 20px;
	background: #202B38;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

.card a {
	color: #FFF;
	text-decoration: none;
}

.card a h3 {
	font-size: 24px;
	letter-spacing: 2px;
	font-weight: 700;
}

.card .platforms {
	position: absolute;
	top: 30px;
	left: 30px;
}

.card .platforms a {
	display: inline-block;
	height: 28px;
	
}

.card h3 {
	padding-left: 20px;
	padding-right: 20px;
	margin-bottom: 15px;
}

.card .desc {
	padding-left: 20px;
	padding-right: 20px;
}

.card-middle {
	display: block;
	width: 345px;
	height: 19px;
	position: absolute;
	top: 237px;
	right: auto;
	background: url(../img/card-middle.svg) center center no-repeat;
	-webkit-background-size: contain;
	     -o-background-size: contain;
	        background-size: contain;
}

.card-bottom {
	display: block;
	width: 54px;
	height: 50px;
	position: absolute;
	bottom: -2px;
	right: -2px;
	background: url(../img/card-corner.svg) center center no-repeat;
	-webkit-background-size: contain;
	     -o-background-size: contain;
	        background-size: contain;
}

.card .card-img img {
	transform-origin: center;
	transform: scale(1);
	transition: transform 1s ease-in-out;
}

.card:hover .card-img img {
	transform-origin: center;
	transform: scale(1.1);
	transition: transform 1s ease-in-out;
}

.games-banner {
	background: url(../img/catalog-banner-bg.jpg) center no-repeat;
	-webkit-background-size: cover;
	     -o-background-size: cover;
	        background-size: cover;
	padding: 40px 80px;
	display: flex;
	text-align: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-flex-direction: row;
	    -ms-flex-direction: row;
	        flex-direction: row;
}

.games-banner h4 {
	font-size: 42px;
	font-weight: 700;
	letter-spacing: 1px;
}

.banner-el-l {
	display: block;
	width: 113px;
	height: 26px;
	background: url(../img/banner-em-l.svg) left center no-repeat;
	-webkit-background-size: contain;
	     -o-background-size: contain;
	        background-size: contain;
}

.banner-el-r {
	display: block;
	width: 113px;
	height: 26px;
	background: url(../img/banner-em-r.svg) left center no-repeat;
	-webkit-background-size: contain;
	     -o-background-size: contain;
	        background-size: contain;
}

.about {
	padding: 100px 0;
	height: 550px;
	background: url(../img/about-bg.jpg) center no-repeat;
	-webkit-background-size: cover;
	     -o-background-size: cover;
	        background-size: cover;
}

.about-img {
	position: relative;
    width: 80%;
    top: -70px;
    left: 60px;
}

.about .socials {
	margin-top: 30px;
	height: auto;
	width: 15%;
	padding-left: 0;
	list-style: none;
}

.career {
	padding: 50px 0 0;
	position: relative;
	z-index: 20;
	
	@media #{$phone}{
		padding: 50px 0;
	}
	
	.title {
		top: 145px;
	}

	.career-text {
		padding-left: 100px;
		
		@media #{$phone}{
			padding-left: 0;
		}
	}

	a {
		margin-top: 40px;
	}

}

.career h5{
	margin-top: 40%;
	font-size: 48px;
	font-weight: 700;
	letter-spacing: 1px;
	
	@media #{$phone}{
		margin-top: 40px;
	}
}

.career img {
	width: 70%;
	margin: 0 auto;
	
	@media #{$phone}{
		display: none;
	}
}

.investors {
	position: relative;
	z-index: 20;
}


.investors-text {
	padding-top: 0px;
	padding-left: 0px;
	
	@media #{$phone}{
		
	}
}

.investors h5{
	margin-top: 40%;
	font-size: 48px;
	font-weight: 700;
	letter-spacing: 1px;
}

.investors img {
	width: 70%;
	margin: 0 auto;
	

}

.investors a {
	color: #4791FF;
	text-decoration: none;
}

.footer-link {
	display: inline-block;
	margin-right: 30px;
}

footer p {
	margin-bottom: 0;
	height: 100%;
	margin-top: 20px;
}

@media (max-width: 767px) {

	.col-top {
		width: 50%;
	}

	.devider {
		display: block;
		margin: 30px 0;
		width: 280px;
		height: 20px;
	}

	.hero {
		height: auto;
	}

	.top .logo img {
		width: 100px;
	}

	.middle {
		margin-top: 50px;
	 	height: 50%;
	 	width: 100%;
	}

	.middle .main-logo {
		width: 100%;
		margin-bottom: 50px;
		padding-left: 0;
	}

	.middle .main-logo img {
		max-width: 100%;
	}

	.middle .scroll {
		width: 100%;
		padding-left: 0px;
		display: flex;
		-webkit-flex-direction: column;
		    -ms-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
	}


	.middle .scroll img {
		width: 80%;
		display: block;
		margin-bottom: 20px;
	}

	.chars {
		width: 90%;
		max-width: 90%;
	    position: relative;
	    top: 50px;
	    left: 0;
	}

	.chars img {
		width: 100%;
	}


	.games-banner {
		height: 400px;
		//background: #334457;
		padding: 40px 20px;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		-webkit-flex-direction: column;
		    -ms-flex-direction: column;
		        flex-direction: column;
	}



	.about {
		padding: 80px 0;
		height: auto;
	}


	.about-text img:nth-child(1) {
		max-width: 70%;
	}

	.investors-text {
		padding-top: 50px;
		padding-left: 0;
	}

	.investors h5{
		margin-top: 5%;
		font-size: 48px;
		font-weight: 700;
		letter-spacing: 1px;
	}


	footer .col-md-4:nth-child(1) {
		text-align: center;
		margin-bottom: 20px;
	}

	footer .col-md-1:nth-child(2) {
		text-align: center;
	}

	footer .col-md-4:nth-child(3) {
		text-align: center;
	}

}

form {
	
	.input-field {
		background: none;
		color: $white;
		width: 100%;
		padding: 10px 0;
		font-size: 16px;
		margin-bottom: 20px;
		border: none;
		border-bottom: 1px solid $white;
		
		&:active, &:focus {
			border: none;
			outline: none;
			border-bottom: 1px solid $blue;
			-webkit-border-radius: 0;
			        border-radius: 0;
		}
	}
}




