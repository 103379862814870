.btn {
	color: #FFF;
	background: url(../img/icons/btn-shape.svg) bottom right no-repeat;
	-webkit-background-size: contain;
	     -o-background-size: contain;
	        background-size: contain;
	width: 300px;
	text-transform: uppercase;
	position: relative;
	display: inline-block;
	height: 48px;
	line-height: 50px;
	padding: 0;
	padding-left: 80px;
	letter-spacing: 0.2em;
	border: none;
	-webkit-border-radius: 0;
	        border-radius: 0;
	transition: letter-spacing 0.8s $ease;
	
	@media #{$phone}{
		width: 100%;
	}

	&.btn-vacancies {
		//width: 320px;
	}
	
	&:hover {
		background: url(../img/icons/btn-shape.svg) bottom right no-repeat;
		border: none;
		padding: 0 0 0 80px;
		-webkit-box-shadow: none;
		        box-shadow: none;
		letter-spacing: 0.3em;
		transition: letter-spacing 0.8s $ease;

	}
	
	
	&:before {
		content: "";
		display: block;
		width: 12px;
		height: 12px;
		position: absolute;
		right: 0;
		bottom: 0;
		background: url(../img/icons/btn-corner.svg) center no-repeat;
		-webkit-background-size: contain;
		     -o-background-size: contain;
		        background-size: contain;
	}

	&:after {
		content: "";
		position: absolute;
		width: 80px;
		height: 48px;
		background: url(../img/icons/btn-shape-l.svg) center no-repeat;
		-webkit-background-size: contain;
		     -o-background-size: contain;
		        background-size: contain;
		top: 0;
		left: 0;
	}
}

.more {
	color: $blue !important;
	display: block;
	letter-spacing: 0em;
	transition: letter-spacing 0.8s $ease;
	
	&:hover {
		letter-spacing: 0.1em;
		transition: letter-spacing 0.8s $ease;
	}
	
	&:after {
		content: '';
		display: inline-block;
		margin-left: 5px;
		width: 18px;
		height: 15px;
		background: url(../img/icons/arrow-icon.svg) center no-repeat;
		-webkit-background-size: contain;
		     -o-background-size: contain;
		        background-size: contain;
	}
}

.back {
	color: $blue !important;
	display: block;
	letter-spacing: 0em;
	transition: letter-spacing 0.8s $ease;
	
	&:hover {
		letter-spacing: 0.1em;
		transition: letter-spacing 0.8s $ease;
	}
	
	&:before {
		content: '';
		display: inline-block;
		margin-right: 5px;
		width: 18px;
		height: 15px;
		background: url(../img/icons/arrow-icon-b.svg) center no-repeat;
		-webkit-background-size: contain;
		     -o-background-size: contain;
		        background-size: contain;
	}
}













